import i18n from "@/i18n";
import formatters from "@/core/components/common/grid/formatters/formatters.js";

export const PromotionValueFormatter = (promotion) => {
  if(promotion?.discount){
    if (promotion.discount.isAPercentDiscount) {
      return formatters.percentageFormatter({ value: promotion.discount.value * 100 })
    }
    else {
      return formatters.currencyFormatter({ value: promotion.discount.value })
    }
  }
  return '';
}

export const PromotionFilterValue = (promotion) => {
  if(promotion?.discount){
    if (promotion.discount.isAPercentDiscount) {
      return promotion.discount.value * 100
    }
    else {
      return promotion.discount.value
    }
  }
  return null;
}

export const PromotionStatusGetter = (isActive) => {
  return isActive ? i18n.t('Promotion.Status.Active') : i18n.t('Promotion.Status.Inactive')
}

export const PromotionTypeGetter = (promotion) => {
  if(promotion?.discount){
    return i18n.t(`Promotion.Types.${promotion.discount.typeCode}`)
  }
  return ''
}
