<template src="./multi-type-input.html"></template>

<style lang="scss">
    @import './multi-type-input.scss';
</style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { CurrencyInput  } from 'vue-currency-input'
import Multiselect from 'vue-multiselect'

export default {
  name: 'multi-type-input',
  model: {
    prop: 'selectedType'
  },
  props:{
    inputTypeLabel: String,
    valueLabel: String,
    required:{
      type: Boolean,
      default: false
    },
    selectedType: {
      type: Object
    },
    inputScopes:{
      type: Array,
      required: true
    },
    disabled:{
      type:Boolean,
      default:false
    },
    inputCurrencyDisabled: {
      type: Boolean,
      default: false
    }
  },
  components:{
    ValidationProvider,
    ValidationObserver,
    CurrencyInput,
    Multiselect
  },
  data() {
    return {
      inputConfigs: {
        locale: this.$i18n.locale,
        distractionFree: false,
        precision: 2
      }
    };
  },
  computed: {
    showLabels(){
      return this.inputTypeLabel || this.valueLabel
    },
    inputSuffix(){
      if(this.selected)
          return this.selected.inputType == 'percentage' ? { suffix: " %" } : { suffix: " $" }

      return { suffix: "" }
    },
    inputPlaceholder(){
      return "0.00 " + this.inputSuffix.suffix
    },
    value:{
      get(){
        return this.selected && this.selected.value || null
      },
      set(value){
        this.$emit('input', { 
          ...this.selected,
          value: value  
        })
      }
    },
    selected:{
      get(){
        return this.selectedType || null
      },
      set(value){
        this.$emit('input', value)
      }
    },
    validateRule() {
      if(this.required)
        return 'positive'

      return null
    }
  }
}

</script>
