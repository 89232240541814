<template src="./promotion-conditions.html"></template>

<style lang="scss">
    @import './promotion-conditions.scss';
</style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import promotionProductScan from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-scan/promotion-product-scan.vue';
import { PromotionScopeCode, DuplicatePromoCodeException, Promotion } from '@/backoffice/modules/promotion/domain/promotion.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications'
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment'
import { DiscountTypeCode } from '@/backoffice/modules/promotion/domain/promotion.js'

export default {
    name: 'promotion-conditions-template',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            promotion: null,
            promotionCopy: null,
            preventSave: false,
            defaultMinMaxValue: 1
        }
    },
    computed:{
        ...mapGetters('Promotion', ['getPromotion', 'getOperationType']),
        isCreate(){
            return this.getOperationType === OperationType.Creation
        },
        isEdit(){
            return this.getOperationType === OperationType.Edition
        },
        isEdited(){
            return this.isEdit && !isEqual(this.promotion, this.promotionCopy)
        },
        //Discount Limit Per Transaction
        disableDiscountMinMaxItemsCheck(){
            return this.promotion?.scope?.scopeCode != PromotionScopeCode.Item
        },
        disableMinItemCheck(){
            return this.promotion.discount.typeCode === DiscountTypeCode.BundleOffer
        },
        isBxGy() {
            return this.promotion?.discount?.isBxGy;
        },
        discountMinItems:{
            get(){
                return this.promotion?.discount?.discountMinItems
            },
            set(val){
                this.promotion.discount.discountMinItems = val

                if(this.isCreate)
                    this.SET_PROMOTION(this.promotion)
            }
        },
        discountMinItemsCheck:{
            get(){
                return this.discountMinItems != null
            },
            set(checked){
                if(!checked) {
                    this.discountMinItems = null
                }
                else {
                    this.discountMinItems = this.setMinMaxValueRule(this.getPromotion?.discount?.discountMinItems)
                }
            }
        },
        disableDiscountMinItemsValue(){
            return this.disableDiscountMinMaxItemsCheck || !this.discountMinItemsCheck || this.promotion.discount.typeCode === DiscountTypeCode.BundleOffer
        },
        validateMinItems() {
            return { 
                evalFunction: !this.discountMinItems || (this.discountMinItems <= this.discountMaxItems),
                customMessage: this.$t('Promotion.Messages.MinItemsCantGreaterTheMaxItems')
            }
        },
        discountMinItemsValidationRules(){
            if (!this.discountMinItemsCheck)
                return null

            return this.discountMaxItems ? { required: true, positive: true, customFunction: this.validateMinItems } : 'required|positive'
        },
        discountMaxItems:{
            get(){
                return this.promotion?.discount?.discountMaxItems
            },
            set(val){
                this.promotion.discount.discountMaxItems = val

                if(this.isCreate)
                    this.SET_PROMOTION(this.promotion)
            }
        },
        discountMaxItemsCheck:{
            get(){
                return this.discountMaxItems != null
            },
            set(checked){
                if(!checked) {
                    this.discountMaxItems = null
                }
                else {
                    this.discountMaxItems = this.setMinMaxValueRule(this.getPromotion?.discount?.discountMaxItems, this.discountMinItems)
                }
            }
        },
        disableDiscountMaxItemsValue(){
            return this.disableDiscountMinMaxItemsCheck || !this.discountMaxItemsCheck
        },
        validateMaxItems() {
            return {
                evalFunction: !this.discountMaxItems || (this.discountMaxItems >= this.discountMinItems),
                customMessage: this.$t('Promotion.Messages.MaxItemsCanLessTheMinItems')
            }
        },
        discountMaxItemsValidationRules(){
            if (!this.discountMaxItemsCheck)
                return null

            return this.discountMinItems ? { required: true, positive: true, customFunction: this.validateMaxItems } : 'required|positive'
        },

        //Promotion Max Usage
        promotionMaxUsage: {
            get(){
                return this.promotion?.maxUsage
            },
            set(newValue){
                if(newValue == 0){
                    this.promotion.maxUsage = null
                }
                else{
                    this.promotion.maxUsage = newValue
                }

                if(this.isCreate)
                    this.SET_PROMOTION(this.promotion)
            }
        },
        currentDate(){
            return moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
        },
        promotionMaxUsageAvailableQty(){
            return this.promotion?.maxUsage ? this.promotion.maxUsage - this.promotion.totalUsage : null
        },
        disablePromotionMaxUsage(){
            return this.promotion?.instant || this.isEdit
        }
    },

    watch:{
        isEdited(val){
            this.$sidePanel.isEdit(val)
        }
    },

    mounted(){
        this.promotion = new Promotion(this.getPromotion)
        this.promotionCopy = new Promotion(this.promotion)
    },

    methods: {
        ...mapActions('Promotion', ['savePromotion']),
        ...mapMutations('Promotion', ['SET_PROMOTION']),
        cancel(){
            this.$sidePanel.close();
        },
        setMinMaxValueRule(value, defaultValue) {
            if (this.isEdit && value > 0)
                return value

            return defaultValue || this.defaultMinMaxValue
        },
        async save(){
            this.preventSave = true;
            const wasEdit = this.isEdit;
            try{
                await this.savePromotion(this.promotion);
            }
            catch(e){
                prompts.error({
                    text: this.$t(e instanceof DuplicatePromoCodeException ? e.message : 'Promotion.Messages.SavePromotionError')
                });
            }

            this.preventSave = false;

            if(this.getPromotion.id > 0){

                prompts.toast({
                    icon: 'success',
                    title: this.$t('Promotion.Messages.SavePromotionSuccess', { promotionCode: this.getPromotion.code }),
                    position: 'bottom-end'
                });

                if(wasEdit){
                    this.promotion = cloneDeep(this.getPromotion)
                    this.promotionCopy = cloneDeep(this.promotion)
                    return
                }

                if (this.getPromotion.scope.scopeCode === PromotionScopeCode.Transaction) {
                    this.$sidePanel.submit(true)
                    return
                }

                this.$sidePanel.show([{ component: promotionProductScan }], null, { backgroundIsDisabled: true });
                this.$router.push({ name: 'promotion', params: { id: this.getPromotion.id } });
            }
        }
    }
}
</script>