import Decimal from "decimal.js";
import { Item } from "@/backoffice/modules/item/domain/item";
import i18n from "@/i18n";

export const PromotionScopeCode = {
  Item: "Item",
  Transaction: "Transaction",
};

export const DiscountTypeCode = {
  FixedPrice: "FixedPrice",
  DollarDiscount: "DollarDiscount",
  PercentDiscount: "PercentDiscount",
  Freebie: "Freebie",
  BundleOffer: "BundleOffer",
  BxGyDollarDiscount: "BxGyDollarDiscount",
  BxGyPercentDiscount: "BxGyPercentDiscount",
  BxGyFixedPrice: "BxGyFixedPrice"
};

export const PromotionSearchByOptionEnum = {
  PROMOTION: 'promotion',
  ITEM: 'item'
}

export const CoveredAmount = {
  Subtotal: 'Subtotal',
  Total: 'Total'
}

export class PromotionItemCategory {
  static BuyX = 'BuyX'
  static GetY = 'GetY'
}


export const PromotionSearchByOption = [
  { value: PromotionSearchByOptionEnum.PROMOTION, text: 'Promotion.SearchBy.Promotion' },
  { value: PromotionSearchByOptionEnum.ITEM, text: 'Promotion.SearchBy.Item' },
]

export class Promotion {
  constructor(promotion) {
    this.id = promotion?.id;
    this.code = promotion?.code;
    this.descriptions = promotion?.descriptions ?? [];
    this.startDate = promotion?.startDate;
    this.endDate = promotion?.endDate;
    this.discount = new PromotionDiscount(promotion?.discount);
    this.scope = new PromotionScope(promotion?.scope);
    this.active = promotion?.active ?? true;
    this.instant = promotion?.instant ?? false;
    this.maxUsage = promotion?.maxUsage;
    this.totalUsage = promotion?.totalUsage ?? 0;
    this.isDeleted = promotion?.isDeleted ?? false;
    this.targetId = promotion?.targetId;
    this.targetDescription = promotion?.targetDescription;
    //TODO this has nothing to do with a domain model
    this.canUpdate = promotion?.canUpdate ?? false;
    this.canDelete = promotion?.canDelete ?? false;
  }

  addItem(item, category = PromotionItemCategory.GetY){
    if(!(item instanceof Item)){
      throw new Error('Invalid item parameter')
    }
    this.discount.addItem(item, category)
  }

  getItemsByCategory(category = PromotionItemCategory.GetY){
    return this.discount.items.filter(i => i.category === category)
  }
}

export class PromotionDiscount {
  #type;
  constructor(discount) {
    this.id = discount?.id;
    this.value = discount?.value ?? 0.0;
    this.typeId = discount?.typeId ?? 0;
    this.#type = discount?.typeCode ?? DiscountTypeCode.DollarDiscount;
    this.discountMinItems = discount?.discountMinItems;
    this.discountMaxItems = discount?.discountMaxItems;
    this.items = discount?.items?.map(i => new PromotionItem(i)) ?? [];
    this.coveredAmount = discount?.coveredAmount ?? CoveredAmount.Subtotal
    this.enforceMinItems()
  }

  set typeCode(type){
    this.#type = type
    this.enforceMinItems()
  }

  get typeCode(){
    return this.#type
  }

  enforceMinItems(){
    if(this.typeCode === DiscountTypeCode.BundleOffer || this.isBxGy){
      this.discountMinItems = null
    }
  }

  addItem(item, category = PromotionItemCategory.GetY){

    // TODO better data structure to enforce the unicity rule
    const existingItem = this.items.find(i => i.upc === item.itemUPC && i.category === category)
    if(existingItem){
      return
    }

    const promotionItem = new PromotionItem()
    // TODO : map other needed properties
    promotionItem.id = item._ItemID
    promotionItem.upc = item.itemUPC
    promotionItem.category = category
    promotionItem.regularPrice = item.itemPrice
    promotionItem.descriptions = [{language: i18n.locale, value: item.description}]
    this.items.push(promotionItem)
  }

  hasItem(promotionItem){
    if(!(promotionItem instanceof PromotionItem)){
      throw new Error('promotionItem need to be instance of PromotionItem')
    }

    return this.items.some(i => i.upc === promotionItem.upc && i.category === promotionItem.category)
  }

  get isAPercentDiscount() {
    return this.typeCode === DiscountTypeCode.PercentDiscount || this.typeCode === DiscountTypeCode.BxGyPercentDiscount
  }

  get isADollarDiscount() {
    return this.typeCode === DiscountTypeCode.DollarDiscount || this.typeCode === DiscountTypeCode.BxGyDollarDiscount
  }

  get isBxGy(){
    return [
      DiscountTypeCode.Freebie,
      DiscountTypeCode.BxGyDollarDiscount,
      DiscountTypeCode.BxGyPercentDiscount,
      DiscountTypeCode.BxGyFixedPrice]
      .includes(this.typeCode)
  }
}

export class PromotionItem {
  constructor(item) {
    this.id = item?.id;
    this.upc = item?.upc;
    this.discountId = item?.discountId ?? 0;
    this.descriptions = item?.descriptions ?? [];
    this.regularPrice = item?.regularPrice ?? 0;
    this.remove = item?.remove ?? false;
    // //TODO this has nothing to do with a domain model
    this.canUpdate = item?.canUpdate ?? false;
    this.canDelete = item?.canDelete ?? false;
    // ----------------------------------------
    this.category = item?.category ?? PromotionItemCategory.GetY
  }
}

export class PromotionScope {
  constructor(scope) {
    this.scopeId = scope?.scopeId ?? 0;
    this.scopeCode = scope?.scopeCode;
    this.isSingleDiscount = scope?.isSingleDiscount ?? true;
    this.canAddItems = scope?.canAddItems ?? true;
    this.types = scope?.types ?? [];
  }
}

export class PromotionDiscountType {
  constructor() {
    this.typeId = 0
    this.typeCode = null
  }
}

export const convertApiPromotionToPromotion = (apiPromotion) => {
  const promotion = new Promotion();

  if(!apiPromotion)
    return promotion;

  promotion.id = apiPromotion.promotionID;
  promotion.targetId = apiPromotion.targetID;
  promotion.code = apiPromotion.promotionCode;
  promotion.scopeId = apiPromotion.promotionScopeID;
  promotion.active = apiPromotion.isActive;
  promotion.instant = apiPromotion.isInstant;
  promotion.startDate = apiPromotion.promotionStart;
  promotion.endDate = apiPromotion.promotionEnd;
  promotion.maxUsage = apiPromotion.maximumUsage || null;
  promotion.totalUsage = apiPromotion.totalUsage;
  promotion.isDeleted = apiPromotion.isDeleted;
  promotion.descriptions = apiPromotion.descriptions.map((description) => ({
    _DescriptionID: description.descriptionID,
    id: description.promotionID,
    language: description.language,
    value: description.value,
  }));
  promotion.discount = convertApiPromotionDiscountToPromotionDiscount(apiPromotion.discounts[0]);
  promotion.scope = convertApiPromotionScopeToPromotionScope(apiPromotion.scope)
  promotion.targetDescription = apiPromotion.targetDescription;
  promotion.canUpdate = apiPromotion.canUpdate;
  promotion.canDelete = apiPromotion.canDelete;

  return promotion;
};

export const convertApiPromotionDiscountToPromotionDiscount = (apiPromotionDiscount) => {
  const discount = new PromotionDiscount();

  if(!apiPromotionDiscount)
    return discount;

  discount.id = apiPromotionDiscount.promotionDiscountID;
  discount.promotionId = apiPromotionDiscount.promotionID;
  discount.typeId = apiPromotionDiscount.promotionDiscountTypeID;
  discount.typeCode = apiPromotionDiscount.promotionDiscountTypeCode;
  discount.value = apiPromotionDiscount.promotionDiscountValue;
  discount.discountMinItems = apiPromotionDiscount.promotionDiscountMinItems;
  discount.discountMaxItems = apiPromotionDiscount.promotionDiscountMaxItems;
  discount.items = apiPromotionDiscount.items.map((item) => convertApiPromotionItemToPromotionItem(item));
  discount.coveredAmount = Object.values(CoveredAmount)[apiPromotionDiscount.coveredAmountType] ?? CoveredAmount.Subtotal
  
  return discount;
};

export const convertApiPromotionItemToPromotionItem = (apiPromotionItem) => {
  const promotionItem = new PromotionItem();

  if(!apiPromotionItem)
    return promotionItem;

  promotionItem.id = apiPromotionItem.promotionItemId;
  promotionItem.upc = apiPromotionItem.itemUPC;
  promotionItem.discountId = apiPromotionItem.discountId;

  if(apiPromotionItem.itemDescriptions)
    promotionItem.descriptions = apiPromotionItem.itemDescriptions.map((description) => ({
        _DescriptionID: description._ItemDescriptionID,
        id: description._ItemID,
        language: description.language,
        value: description.itemDescription1,
      })
    );

  promotionItem.regularPrice = apiPromotionItem.regularPrice;
  promotionItem.remove = false;
  promotionItem.canUpdate = apiPromotionItem.canUpdate;
  promotionItem.canDelete = apiPromotionItem.canDelete;
  promotionItem.category = apiPromotionItem.category;

  return promotionItem;
};

export const convertApiPromotionScopeToPromotionScope = (apiPromotionScope) => {
  const promotionScope = new PromotionScope();

  if(!apiPromotionScope)
    return promotionScope;

  promotionScope.scopeId = apiPromotionScope.promotionScopeID;
  promotionScope.scopeCode = apiPromotionScope.promotionScopeCode;
  promotionScope.isSingleDiscount = apiPromotionScope.singleDiscount;
  promotionScope.canAddItems = apiPromotionScope.acceptItems;
  promotionScope.types = apiPromotionScope.discountTypes.map((promotionDiscountType) => convertApiPromotionDiscountTypeToPromotionDiscountType(promotionDiscountType));
  
  return promotionScope;
};

export const convertApiPromotionDiscountTypeToPromotionDiscountType = (apiPromotionDiscountType) => {
	const promotionDiscountType = new PromotionDiscountType()

  if(!apiPromotionDiscountType)
    return promotionDiscountType;

	promotionDiscountType.typeId = apiPromotionDiscountType.promotionDiscountTypeID
	promotionDiscountType.typeCode = apiPromotionDiscountType.promotionDiscountTypeCode

  return promotionDiscountType;
};

export const convertPromotionToApiPromotion = (promotion) => {
  if(!promotion)
    return;

  const apiPromotion = {
    promotionID: promotion.id,    
    targetID: promotion.targetId,
    promotionCode: promotion.code,
    promotionScopeID: promotion.scope.scopeId,
    isActive: promotion.active,
    isInstant: promotion.instant,
    promotionStart: promotion.startDate,
    promotionEnd: promotion.endDate,
    maximumUsage: promotion.maxUsage,
    descriptions: promotion.descriptions ? promotion.descriptions.map((description) => ({
      descriptionID: description._DescriptionID,
      promotionID: description.id,
      language: description.language,
      value: description.value
    })) : [],
    discounts: [converPromotionDiscountToApiPromotionDiscount(promotion.discount, promotion.id)],
    scope: convertPromotionScopeToApiPromotionScope(promotion.scope)
  };
  
  return apiPromotion;
};

// TODO remove promotionId checking from promotionDiscount in backend. This is not making sense since the promotionDiscount is nested in promotion
export const converPromotionDiscountToApiPromotionDiscount = (promotionDiscount, promotionId) => {
  if(!promotionDiscount)
    return;

	const apiPromotionDiscount = {
		promotionDiscountID: promotionDiscount.id,
		promotionID: promotionId,
		promotionDiscountTypeID: promotionDiscount.typeId,
		promotionDiscountTypeCode: promotionDiscount.typeCode,
		promotionDiscountValue: promotionDiscount.value,
		promotionDiscountMinItems: promotionDiscount.discountMinItems,
		promotionDiscountMaxItems: promotionDiscount.discountMaxItems,    
		items: promotionDiscount.items.map((item) => convertPromotionItemToApiPromotionItem(item)),
    coveredAmount: promotionDiscount.coveredAmount
	}
	return apiPromotionDiscount
}

export const convertPromotionItemToApiPromotionItem = (promotionItem, promotionDiscount) => {
  if(!promotionItem)
    return;

	const apiPromotionItem = {
    promotionItemID: promotionItem.id,
    itemUPC: promotionItem.upc,
    discountId: promotionDiscount?.id ?? promotionItem.discountId,
    isDeleted: promotionItem.isDeleted,
    category: promotionItem.category
	}
	
	return apiPromotionItem
}

export const convertPromotionScopeToApiPromotionScope = (promotionScope) => {
  if(!promotionScope)
    return;

	const apiPromotionScope = {
    promotionScopeID: promotionScope.scopeId,
    promotionScopeCode: promotionScope.scopeCode,
    singleDiscount: promotionScope.isSingleDiscount,
    acceptItems: promotionScope.canAddItems
	}
	
	return apiPromotionScope
}

export const calculateDiscountedPrice = (discount, price) => {

  if(discount.typeCode === DiscountTypeCode.BundleOffer){

    const regularPrices = discount.items.map(i => i.regularPrice ?? 0)
    const itemRegularPriceSum = 
      regularPrices.reduce((sum, value) => sum.add(value), new Decimal(0)).toNumber()

    let discountedPrice = Decimal.div(price, itemRegularPriceSum).mul(discount.value).toDecimalPlaces(2).toNumber()

    const maxRegularPrice = regularPrices.reduce((max, p) => Math.max(max, p), -Infinity)
    if(price === maxRegularPrice){
      const regularPricesWithoutMax = regularPrices.filter(p => p !== price);

      let discountedPriceSum = regularPricesWithoutMax.reduce((sum, p) => sum.add(calculateDiscountedPrice(discount, p)), new Decimal(0))
      discountedPriceSum = discountedPriceSum.add(discountedPrice).toDecimalPlaces(2).toNumber()

      discountedPrice-= (discountedPriceSum - discount.value)
    }

    return discountedPrice
  }

  let value = discount?.value
  if (discount?.isAPercentDiscount) {
    value = price -  (price * discount.value)
  }
  else if (discount?.isADollarDiscount) {
    value = price - discount.value
  }

  return value < 0 ? 0 : value
};

export const updatePromotionIdsFromApiPromotion = (promotion, apiPromotion) => {
  promotion.id = apiPromotion.promotionID;
  for (const apiDescription of apiPromotion.descriptions) {
    const description = promotion.descriptions.find((d) => d.language === apiDescription.language);
    if (description) description._DescriptionID = apiDescription.descriptionID;
  }

  const apiDiscount = apiPromotion.discounts.length > 0 ? apiPromotion.discounts[0] : null;
  if (apiDiscount) promotion.discount.id = apiDiscount.promotionDiscountID;
};

export class DuplicatePromoCodeException extends Error {
  constructor(){
    super('Promotion.Messages.DuplicatedPromotionCode')
    this.name = 'DuplicatePromoCodeException'
  }
}