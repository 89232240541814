<template src="./inventory-product-scan.html"></template>

<script>
import documentServices from '@/backoffice/modules/inventory/services/document.services.js'
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import Swal from 'sweetalert2';
import inventoryProductSearch from '@/backoffice/modules/inventory/components/side-panel/inventory-product-search/inventory-product-search.vue';
import inventoryProductAdjustment from '@/backoffice/modules/inventory/components/side-panel/inventory-product-adjustment/inventory-product-adjustment.vue';
import inventoryProductCount from '@/backoffice/modules/inventory/components/side-panel/inventory-product-count/inventory-product-count.vue';
import ScanProduct from '@/core/components/layout/side-panel/scan-product/scan-product.vue';

export default {
    name: 'inventory-product-scan',
    props:{
        templatePayload: Object
    },
    components: {
        ScanProduct
    },
    data() {
        return {
            sidePanelComponents: {
                'InventoryProductAdjustment': [{ component: inventoryProductAdjustment }],
                'InventoryProductCount': [{ component: inventoryProductCount }]
            },
            selected: false
        };
    },
    computed:{
        ...mapGetters('Inventory', ['getDocument']),
        ...mapGetters('Account', ['getCurrentTargetLayerId'])
    },
    methods: {
        ...mapActions('Inventory', ['validate']),

        getIcon() {
            return documentServices.getIcon(this.getDocument.documentType);
        },
        cancel(){
            this.$sidePanel.close();
        },
        search(){
            this.$sidePanel.show([{ component: inventoryProductSearch }], null, { classes: ['side-panel--sticky-footer'] });
        },

        async select(product) {
            if (this.selected)
                return

            this.selected = true

            try {
                const validationObject = {
                    id:this.getDocument.id,
                    targetLayerId : this.getCurrentTargetLayerId,
                    upc : product.itemUPC,
                    sku : product.itemSKU,
                    docType:   this.getDocument.documentType,
                    documentStatus: this.getDocument.documentStatus
                };

                const inProgressDocument = await this.validate(validationObject);
                if (inProgressDocument) {
                    const result = await Swal.fire({    
                        heightAuto: false,
                        confirmButtonText: this.$i18n.t('Inventory.Messages.DoubleCount.ConfirmActionText'),
                        showCancelButton: true,
                        cancelButtonText: this.$i18n.t('Inventory.Messages.DoubleCount.CancelActionText'),
                        icon: 'question',
                        title: this.$i18n.t('Inventory.Messages.DoubleCount.Title'),
                        text: this.$i18n.t('Inventory.Messages.DoubleCount.Message'),
                    });

                    if (result.isConfirmed) {
                        this.$sidePanel.close();
                        this.$router.push({ 
                            name: 'document', 
                            params: { 
                                id: inProgressDocument.id 
                            },
                            query: { 
                                searchFilters: [ product.itemUPC ] 
                            } 
                        });
                    }
                }
                else {
                    const inventoryProductTemplate = documentServices.getSidePanelTemplate(this.getDocument.documentType);
                    this.$sidePanel.show(this.sidePanelComponents[inventoryProductTemplate], { itemUPC: product.itemUPC });
                }
            }
            catch(e){
                prompts.error({
                    text: this.$t('Inventory.Messages.SearchProducts')
                })
            }
            finally {
                this.selected = false
            }
        }
    }
}
</script>