<template src="./header.html"></template>

<style scoped lang="scss">
@import "./header.scss";
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import dropdown from '@/core/components/common/drop-down/drop-down/drop-down.vue';
import breadcrumb from '@/core/components/layout/breadcrumb/breadcrumb.vue'
import { languageMapping } from '@/core/domain/language/language.const.js'

export default {
  name: 'header-nav',
  components:{
    dropdown,
    breadcrumb
  },
  data() {
    return {
      language: {
        showOptions: false,
        options: [
          {
            description: 'Language.French',
            clickEvent: () => {
              this.setAppLanguage(languageMapping.fr);
            }
          },
          {
            description: 'Language.English',
            clickEvent: () => {
              this.setAppLanguage(languageMapping.en);
            }
          }
        ]
      },
      targetlayers: {
        label: null,
        showOptions: false,
        options: []
      },
      connection: {
        label: null,
        showOptions: false,
        options: [
          {
            description: 'Login.Logout',
            clickEvent: () => {
              this.logout();
            }
          },
          {
            description: 'Login.Profil',
            clickEvent: () => {
              this.$router.push({ name: 'Profil' });
            }
          },
          {
            description: 'General.ReleaseNote.Title',
            clickEvent: () => {
              this.$router.push({ name: 'releaseNotes' });
            }
          }
        ]
      },
      iframeMonitor: null
    }
  },

  computed: {
    ...mapGetters('Account', [
      'getFirstname',
      'getCurrentTargetLayer',
      'getTargetLayers'
    ]),
    ...mapGetters('App', [
      'isMobile',
      'getSideNavStatus',
      'isIframe'
    ])
  },
  
  watch: {
    isIframe:{
      handler(val){
        if(val){
          this.setIframeDetection();
        }
        else{
          clearInterval(this.iframeMonitor);
        }
      },
      immediate: true    
    }
  },

  mounted() {
    this.setTargetLayers();
    this.connection.label = this.getFirstname;
    this.targetlayers.label = this.getCurrentTargetLayer.description;  
  },

  beforeDestroy(){
    clearInterval(this.iframeMonitor);
    this.iframeMonitor = null;
  },

  methods: {
    ...mapActions('Account', [
      'logout',
      'fetchNewTargetLayerToken'
    ]),
    ...mapActions('App', [
      'setSideNavStatus',
      'setAppLanguage'
    ]),
    setTargetLayers(){
      this.targetlayers.options = [];

      this.getTargetLayers.forEach((targetLayer) => {
        let desc = targetLayer.description;

        if(targetLayer.code){
          desc = desc + " " + targetLayer.code;
        }

        this.targetlayers.options.push({
          description: desc,
          _TargetLayerID: targetLayer._TargetLayerID,
          indent: targetLayer.targetLayerPriority,
          clickEvent: () => {
            this.setNewTargetLayer(targetLayer);  
          }
        });
      });
    },

    async setNewTargetLayer(targetLayer){
      await this.fetchNewTargetLayerToken(targetLayer)
          
      this.targetlayers.label = targetLayer.description;
      this.targetlayers.showOptions = false;
    },

    show(menu) {
      menu.showOptions = !menu.showOptions;
    },

    hide(e, menu) {
      if (e.srcElement.localName != "input"){
        menu.showOptions = false;
      }
    },

    selected(option){
      option.clickEvent()
    },

    toggleSideNav(){
      this.setSideNavStatus(!this.getSideNavStatus);
    },

    setIframeDetection(){
      this.iframeMonitor = setInterval(() => {
        const elem = document.activeElement;
        if(elem?.id === 'iframe'){
          this.language.showOptions = false;
          this.targetlayers.showOptions = false;
          this.connection.showOptions = false;
        }
      }, 100);
    }
  }
}
</script>
