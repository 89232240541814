import Api from '@/core/services/_api.config';

const securityFacadeEndpoint = 'api/SecurityFacade'
const userAccessEndpoint = 'api/UserAccess'

export async function saveUserAccess(userAccess) {
  const response = await Api().put(`${securityFacadeEndpoint}/UserAccess`, userAccess)
  return response.data
}

export async function createUserAccess(userAccess) {
  const response = await Api().post(`${securityFacadeEndpoint}/UserAccess`, userAccess)
  return response.data
}

export async function deleteUserAccess(userAccess) {
  const response = await Api().delete(`/api/SecurityFacade/UserAccess`, { data: userAccess })
  return response.data
}

export async function getUserAccesses(userId){
  const response = await Api().get(`${securityFacadeEndpoint}/GetUserAccesses?userId=${userId}`)
  return response.data
}

export async function resendActivationEmail(userId){
  await Api().post(`${userAccessEndpoint}/SendActivationEmail`, userId)
}