import { extend, configure, localize } from "vee-validate";
import { required, email, regex, integer, max, min_value, alpha_num } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

configure({
    defaultMessage: (field, values) => {
      return i18n.t(`Validation.${values._rule_}`, values);
    },
    classes: {      
      invalid: 'is-invalid',
    }
  });
  
// Install required rule.
extend("required", required);
// Install email rule.
extend("email", email);
// Install email rule.
extend("regex", regex);
// Install integer rule
extend("integer", integer);
extend("max", max);
extend("min_value", min_value)
extend("alphanumericals", alpha_num)

extend("regexName", value => {
  const re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  return re.test(value);
});

extend("Username", value => {
  const re = /^[_A-z0-9]*$/g;
  return re.test(value);
});

extend("PostalCodeCan", value => {
  const reCan = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/u;
  return reCan.test(value.toUpperCase());
});

extend("PostalCodeUS", value => {
  const reUS = /^\d{5}(?:[-\s]\d{4})?$/u;
  return reUS.test(value);

});

extend("phone", value => {
  const number = value.includes('ext') ? value.substr(0, value.indexOf('ext')) : value;
  const formatted = number.replace(/[^0-9&&^.]/g, "");
  return formatted.length > 9;
});

extend("positive", value => {
  return value > 0;
})

extend("max200", value => {
  return value.length < 200;
});

extend("max100", value => {
  return value.length < 100;
});

extend("max40", value => {
  return value.length < 40;
});

extend("customFunction", {
  params: ["evalFunction", "customMessage"],
  validate: (value, { evalFunction, customMessage }) => {
    if(evalFunction)
      return true;
      
    return customMessage;
  }
});
localize({
  en: {
    messages: {
      email: "Email is not valid",
      regexName: "Invalid characters",
      alphanumericals: "Alphanumeric characters only",
      required: "This field is mandatory",
      integer: "Decimals are forbidden. Use integer.",
      regex: "Special characters forbidden",
      max200: "200 characters maximum",
      max100: "100 characters maximum",
      max40: "40 characters maximum",
      positive: "Insert a positive value",
      max: "Too many characters",
      min_value: "The value is too low"
    },
    fields: {
      email: {
        email: "Invalid email"
      },
      zipCode:{
        PostalCodeCan: "Invalid zip code",
        PostalCodeUS: "Invalid zip code"
      },
      phone:{
        phone: "Invalid phone"
      },
      descriptionValidator:{
        customFunction: "Insert at least one description",
        required: "Insert at least one description"
      },
      multiTypeInputValidator:{
        required: "Insert a positive value"
      }
    }
  },
  fr: {
    messages: {
      email: "Courriel invalide",
      regexName: "Caractères invalides",
      alphanumericals: "Caractères alphanumériques seulement",
      required: "Ce champ est obligatoire",
      integer: "Les décimaux sont interdit. Utilisez des entiers",
      regex: "Caractères spéciaux interdit",
      max200: "200 Caractères maximum",
      max100: "100 Caractères maximum",
      max40: "40 Caractères maximum",
      positive: "Entrez une valeur positive",
      max: "Trop de caractères",
      min_value: "La valeur est trop basse"
    },
    fields: {
      email: {
        email: "Courriel invalide",
      },
      zipCode:{
        PostalCodeCan: "Code postal invalide",
        PostalCodeUS: "Code postal invalide"
      },
      phone:{
        phone: "Téléphone invalide"
      },
      descriptionValidator:{
        customFunction: "Veuillez entrer au moins une description",
        required: "Veuillez entrer au moins une description"
      },
      multiTypeInputValidator:{
        required: "Entrez une valeur positive"
      }
    }
  }
});