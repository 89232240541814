<template src="./drop-down.html"></template>
<style lang="scss" scoped src="./drop-down.scss"></style>

<script>
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import { stringFunction } from '@/core/functions/string.js';

export default {
  name: 'drop-down',
  components:{
    searchBar
  },
  props: {
    options: Array,
    search: Boolean,
    translate: {
      type: Boolean,
      default: () => {
          return false
      }
    }
  },

  data() {
    return {
      filteredOptions: []
    }
  },
  
  mounted(){
    this.filteredOptions = this.options;
  },

  watch: {
    options: {
      handler(newVal){
        this.filteredOptions = newVal;
      },
      deep: true
    }
  },

  methods: {
    cb(option){
      this.$emit('selected', option)
    },

    filtersChanged(filters){
      let filteredOptions = this.options || [];
      if (filters.length) {
        const ciaiFilter = stringFunction.removeAccent(filters[0].toLowerCase());
        filteredOptions = filteredOptions.filter(e => {
          const ciaiDescription = stringFunction.removeAccent(e.description.toLowerCase());
          return ciaiDescription.includes(ciaiFilter)
        });
      }

      this.filteredOptions = filteredOptions;
    }
  }
}
</script>